<div class="login-wrapper d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="box mat-card">
    <mat-card-header>
      <mat-card-title><h1>IO-ELON</h1></mat-card-title>
    </mat-card-header>

      <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card-content>
        <mat-form-field class="w-100 transparent" appearance="fill">
          <mat-label>Benutzername</mat-label>
          <input type="text" name="username" matInput placeholder="Benutzername" formControlName="username" required>
          <mat-error *ngIf="isFieldInvalid('username')">
            Bitte geben Sie Ihr Benutzername ein.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 transparent" appearance="fill">
          <mat-label>Passwort</mat-label>
          <input matInput name="password" type="password" placeholder="Passwort" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Bitte geben Sie Ihr Passwort ein.
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="loginInvalid()">
            Benutzername oder Passwort falsch.
        </mat-error>
        <mat-error *ngIf="communicationError">
            Kommunikation mit dem Server fehlgeschlagen. Kontaktieren Sie den IO-Dynamics Support: +49 (0) 461 402 142-10
        </mat-error>
        <button [disabled]="loading" id="btn-login" mat-stroked-button class="bg-blue-1 d-block w-100" type="submit">Login</button>
        <a [routerLink]="['/reset']" class="pt-2">Password vergessen?</a>
        </mat-card-content>
      </form>
  </mat-card>
</div>
