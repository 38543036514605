<mat-toolbar>
    <span>Fahrzeug-Typen Übersicht</span>
    <div class="spacer"></div>
    <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <div class="d-flex flex-column">
            @for (column of columnDefinitions; track column.def) {
                <mat-checkbox
                    (change)="toggleColumn(column.def)"
                    [checked]="isColumnVisible(column.def)"
                    (click)="$event.stopPropagation()"
                >
                    {{ column.def }}
                </mat-checkbox>
            }
        </div>
    </mat-menu>
</mat-toolbar>
<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>
                <span [style.paddingLeft.px]="40"> ID </span>
            </th>
            <td mat-cell *matCellDef="let data"
                [ngStyle]="{'padding-left': (data.level * 32)+10 + 'px', 'min-width': (data.level * 70)+50 + 'px'}">
                <button mat-icon-button [style.visibility]="!data.expandable ? 'hidden' : ''"
                        (click)="treeControl.toggle(data)">
                    <mat-icon
                        class="mat-icon-rtl-mirror">{{ treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                </button>
                {{ data.id }}
            </td>
        </ng-container>

        <ng-container matColumnDef="manufacturer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
            <td mat-cell *matCellDef="let data">{{ data.manufacturer }}</td>
        </ng-container>

        <ng-container matColumnDef="modelName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ModelName</th>
            <td mat-cell *matCellDef="let data">{{ data.modelName }}</td>
        </ng-container>

        <ng-container matColumnDef="vehicleClass">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>VehicleClass</th>
            <td mat-cell *matCellDef="let data">{{ data.vehicleClass }}</td>
        </ng-container>

        <ng-container matColumnDef="maxDcPower">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>maxDcPower</th>
            <td mat-cell *matCellDef="let data">{{ data.maxDcPower }}</td>
        </ng-container>

        <ng-container matColumnDef="minDcPower">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>minDcPower</th>
            <td mat-cell *matCellDef="let data">{{ data.minDcPower }}</td>
        </ng-container>

        <ng-container matColumnDef="maxAcAmpsI1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>maxAcAmpsI1</th>
            <td mat-cell *matCellDef="let data">{{ data.maxAcAmpsI1 }}</td>
        </ng-container>

        <ng-container matColumnDef="maxAcAmpsI2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>maxAcAmpsI2</th>
            <td mat-cell *matCellDef="let data">{{ data.maxAcAmpsI2 }}</td>
        </ng-container>

        <ng-container matColumnDef="maxAcAmpsI3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>maxAcAmpsI3</th>
            <td mat-cell *matCellDef="let data">{{ data.maxAcAmpsI3 }}</td>
        </ng-container>

        <ng-container matColumnDef="minAcAmpsI1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>minAcAmpsI1</th>
            <td mat-cell *matCellDef="let data">{{ data.minAcAmpsI1 }}</td>
        </ng-container>

        <ng-container matColumnDef="minAcAmpsI2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>minAcAmpsI2</th>
            <td mat-cell *matCellDef="let data">{{ data.minAcAmpsI2 }}</td>
        </ng-container>

        <ng-container matColumnDef="minAcAmpsI3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>minAcAmpsI3</th>
            <td mat-cell *matCellDef="let data">{{ data.minAcAmpsI3 }}</td>
        </ng-container>

        <ng-container matColumnDef="capacityBruttoNew">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>capacityBruttoNew</th>
            <td mat-cell *matCellDef="let data">{{ data.capacityBruttoNew }}</td>
        </ng-container>

        <ng-container matColumnDef="capacityNettoNew">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>capacityNettoNew</th>
            <td mat-cell *matCellDef="let data">{{ data.capacityNettoNew }}</td>
        </ng-container>

        <ng-container matColumnDef="minSocU">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>minSocU</th>
            <td mat-cell *matCellDef="let data">{{ data.minSocU }}</td>
        </ng-container>

        <ng-container matColumnDef="maxSocU">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>maxSocU</th>
            <td mat-cell *matCellDef="let data">{{ data.maxSocU }}</td>
        </ng-container>

        <ng-container matColumnDef="bestSocU">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>bestSocU</th>
            <td mat-cell *matCellDef="let data">{{ data.bestSocU }}</td>
        </ng-container>

        <ng-container matColumnDef="imageId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>imageId</th>
            <td mat-cell *matCellDef="let data">{{ data.imageId }}</td>
        </ng-container>

        <ng-container matColumnDef="fullRange">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>fullRange</th>
            <td mat-cell *matCellDef="let data">{{ data.fullRange }}</td>
        </ng-container>

        <ng-container matColumnDef="socMap">
            <th mat-header-cell *matHeaderCellDef>socMap</th>
            <td mat-cell *matCellDef="let data">{{ data.socMap }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
    </table>
</div>

