import {Injectable} from '@angular/core';
import {CacheManager, CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {VehicleType, VehicleTypeTree} from '@io-elon-common/frontend-api/lib/model/models';
import {ApiService} from '../../../services/api-handlers/api.service';
import {POLL_INTERVALS} from '../../../app.module';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleTypeService extends CacheManager{
    private vehicleListCache: CacheUpdater<VehicleType[], undefined>
    public constructor(
        private readonly apiService: ApiService
    ) {
        super(POLL_INTERVALS.vehicle_type);
        this.vehicleListCache = this.createManagedCache(() => true);
    }

    public getVehicleTypes(): BehaviorSubject<VehicleType[] | undefined> {
        return this.vehicleListCache.getOrCreateGet(
            undefined,
            ()=>this.apiService.getVehicleTypeList(true).toPromise().then(list => list.list)
        ).data
    }

    public getVehicleTypesTree(): Promise<VehicleTypeTree> {
        return this.apiService.getVehicleTypeTree(true).toPromise();
    }
}

