<mat-icon class="icon" matTooltip="Liste herunterladen" [matMenuTriggerFor]="selectionMenu">download</mat-icon>

<mat-menu #selectionMenu>

    <button mat-menu-item [matMenuTriggerFor]="csvDialects" > <i class="fa-solid fa-file-csv"></i> CSV </button>
    <button mat-menu-item (click)="export('xlsx')"> <i class="fa-solid fa-file-excel"></i> Microsoft Excel </button>
    <button mat-menu-item (click)="export('ods')"> <i class="fa-solid fa-file"></i> LibreOffice </button>

</mat-menu>

<mat-menu #csvDialects="matMenu">
    @for(d of csvDialect; track d.name) {
        <button (click)="export('csv', d.date, d.fs, d.ds)" mat-menu-item>
            <div class="d-flex flex-row gap-2">
                <div class="flex-grow-0">{{d.name}}</div>
                <div class="d-flex flex-column flex-grow-0">
                    <small>Datum:</small>
                    <small>Feld Trenner:</small>
                    <small>Dezimaltrenner:</small>
                </div>
                <div class="d-flex flex-column flex-grow-0">
                    <small>{{d.date}}</small>
                    <small>{{d.fs}}</small>
                    <small>{{d.ds}}</small>
                </div>
            </div>
        </button>
    }
</mat-menu>
