import {Component, Input} from '@angular/core';
import {PhaseValues, SinglePhaseElectricity, ThreePhaseElectricity} from '../../../../../shared/helper/electricity';

@Component({
  selector: 'app-electricity-bar',
  templateUrl: './electricity-bar.component.html',
  styleUrl: './electricity-bar.component.scss'
})
export class ElectricityBarComponent {
    @Input()
    ampsMode = false;

    expertMode = false;

    @Input()
    electricity!: ThreePhaseElectricity;

    @Input()
    plannedAmps?: PhaseValues

    @Input()
    maxAmps!: PhaseValues;

    public isOpen = false;

    zeroIfNan(number: number) {
        return Number.isNaN(number) ? 0 : number;
    }

    isPositive(phase: SinglePhaseElectricity): boolean {
        return phase.getWatts() >= 0;
    }
    isPositiveSum(): boolean {
        return this.electricity.getPSum() >= 0;
    }
}
