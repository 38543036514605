<div class="body d-flex flex-column justify-content-start align-items-stretch">
    <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Name</mat-label>
            <input [formControl]="name" matInput [(ngModel)]="data.name">
            <mat-hint>Name des Fahrzeugs vergeben</mat-hint>
            <mat-error *ngIf="name">Name ist erforderlich</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Typ</mat-label>
            <input type="text"
                   matInput
                   [formControl]="fc"
                   required
                   (selectionchange)="onVehicleTypeChange($event)"
                   [matAutocomplete]="autoGroup">
            <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of manufacturerGroupOptions | async">
                    <span style="font-weight: bold">{{ group.manufacturer }}</span>
                    <mat-option *ngFor="let veh of group.vehicles" [value]="veh.modelName">
                        <img
                            ngSrc="/api/vehicleType/{{veh.id}}/image"
                            height="45"
                            width="45"
                            alt="Vehicle Picture">{{veh.modelName}}
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Nummernschild</mat-label>
            <input matInput [(ngModel)]="data.numberPlate">
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Fahrzeugnummer</mat-label>
            <input type="number" matInput [(ngModel)]="data.localId" required>
            <mat-hint>Frei vergebbare Fahrzeugnummer innerhalb der Flotte</mat-hint>
        </mat-form-field>
    </div>
    <hr>
    <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Anbindung</mat-label>
            <mat-select [(ngModel)]="dataSourceType" (valueChange)="onTypeChange($event)">
                <mat-option [value]="'Keine Datenquelle'">Keine Datenquelle</mat-option>
                <mat-option [value]="'IO-ELON Dongle'">IO-ELON Dongle</mat-option>
                <ng-container *ngIf="(teslaAccounts | async) as teslaAccounts; else disabledTesla">
                    <mat-option [disabled]="teslaAccounts.length == 0" [value]="'Tesla API'">Tesla API</mat-option>
                </ng-container>
                <ng-template #disabledTesla>
                    <mat-option disabled [value]="'Tesla API'">Tesla API (Accounts werden geladen)</mat-option>
                </ng-template>
                <mat-option disabled [value]="'BMW Connected Drive Account'">BMW Connected Drive Account</mat-option>
                <ng-container *ngIf="(freeToMoves | async) as freeToMoves; else disabledF2M">
                    <mat-option [disabled]="freeToMoves.length == 0" [value]="'Free2Move'">Free2Move</mat-option>
                </ng-container>
                <ng-template #disabledF2M>
                    <mat-option disabled [value]="'Free2Move'">Free2Move (Accounts werden geladen)</mat-option>
                </ng-template>
                <mat-option [value]="'Mercedes Benz API'">Mercedes Benz API</mat-option>
                <mat-option [value]="'Tronity'">Tronity</mat-option>
                <mat-option [value]="'Rio'">Rio</mat-option>
                <ng-container *ngIf="(sTecAccounts | async) as sTecAccounts; else disabledSTec">
                    <mat-option [disabled]="sTecAccounts.length == 0" [value]="'S-Tec'">S-Tec</mat-option>
                </ng-container>
                <ng-template #disabledSTec>
                    <mat-option disabled [value]="'S-Tec'">S-Tec (Accounts werden geladen)</mat-option>
                </ng-template>
                <mat-option [value]="'Kuantic'">Kuantic</mat-option>
                <mat-option [value]="'WebFleet'">WebFleet</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex-grow-1 w-50"></div>
    </div>

    <ng-container [ngSwitch]="dataSourceType">
        <!-- Dongle -->
        <ng-container *ngSwitchCase="'IO-ELON Dongle'">
            <ng-container *ngIf="data.connection.dongle">
                <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
                    <mat-form-field *ngIf="(dataSources | async) as dataSources; else noDataSources" appearance="fill" class="flex-grow-1">
                        <mat-label>Datenquelle</mat-label>
                        <mat-select [(ngModel)]="data.connection.dongle.dataSourceId">
                            <mat-option *ngFor="let ds of dataSources" [value]="ds.id">{{ds.name}} <span class="data-source-info mat-caption">{{ds.info}}</span></mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #noDataSources>
                        <div class="flex-grow-1 w-50" *ngIf="dataSources === null">Bitte Anbindung wählen</div>
                        <div class="flex-grow-1 w-50" *ngIf="dataSources !== null">Loading...</div>
                    </ng-template>
                    <div class="flex-grow-1 w-50"></div>
                </div>
            </ng-container>
        </ng-container>

        <!-- Free 2 Move -->
        <ng-container *ngSwitchCase="'Free2Move'">
            <ng-container *ngIf=" data.connection.free2move">
                <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
                    <mat-form-field *ngIf="(freeToMoves | async) as freeToMoves; else noDataSourcesF2M" appearance="fill" class="flex-grow-1">
                        <mat-label>Verknüpfung</mat-label>
                        <mat-select [(ngModel)]="data.connection.free2move.dataSourceId">
                            <mat-option *ngFor="let ds of freeToMoves" [value]="ds.id">{{ds.vin}} <span class="data-source-info mat-caption">{{ds.mail}}</span></mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #noDataSourcesF2M>
                        <div class="flex-grow-1 w-50" *ngIf="freeToMoves === null">Bitte Anbindung wählen</div>
                        <div class="flex-grow-1 w-50" *ngIf="freeToMoves !== null">Loading...</div>
                    </ng-template>
                    <div class="flex-grow-1 w-50"></div>
                </div>
            </ng-container>
        </ng-container>

        <!-- Tesla -->
        <ng-container *ngSwitchCase="'Tesla API'">
            <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
                <mat-form-field *ngIf="(dataSources | async) as dataSources; else noDataSources" appearance="fill" class="flex-grow-1">
                    <mat-label>Account</mat-label>
                    <mat-select [(ngModel)]="$any(data.connection.tesla).teslaAccountId" (selectionChange)="onTeslaAccountChange()">
                        <mat-option *ngFor="let ds of dataSources" [value]="ds.id">{{ds.name}} <span class="data-source-info mat-caption">{{ds.info}}</span></mat-option>
                    </mat-select>
                    <mat-hint><a routerLink="/teslaAccounts">Neue Account hier anlegen</a></mat-hint>
                </mat-form-field>
                <ng-template #noDataSources>
                    <div *ngIf="dataSources === null">Bitte Anbindung wählen</div>
                    <div *ngIf="dataSources !== null">Loading...</div>
                </ng-template>

                <mat-form-field *ngIf="(teslaVehicles | async) as teslaVehicles; else loading" appearance="fill" class="flex-grow-1">
                    <mat-label>Fahrzeug</mat-label>
                    <mat-select [(ngModel)]="$any(data.connection.tesla).teslaVehicleId">
                        <mat-option *ngFor="let v of teslaVehicles" [value]="v.teslaVehicleId">{{v.displayName}} <span class="data-source-info mat-caption">{{v.teslaVehicleId}}</span></mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-template #loading>
                    <div class="w-50 flex-grow-1">Loading...</div>
                </ng-template>
            </div>
        </ng-container>

        <!-- Mercedes -->
        <ng-container *ngSwitchCase="'Mercedes Benz API'">
            <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
                <mat-form-field appearance="fill" *ngIf="data.connection.mercedes">
                    <mat-label>VIN</mat-label>
                    <input matInput type="string" [(ngModel)]="data.connection.mercedes.vin">
                    <mat-hint>Vehicle Information Number des Fahrzeugs</mat-hint>
                </mat-form-field>
                <div class="flex-grow-1 w-50"></div>
            </div>
        </ng-container>

        <!-- Rio -->
        <ng-container *ngSwitchCase="'Rio'">
            <ng-container *ngIf="rioEnterAssetId">
                <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
                    <mat-form-field appearance="fill" *ngIf="data.connection.rio">
                        <mat-label>RIO Asset ID</mat-label>
                        <input matInput type="string" [(ngModel)]="data.connection.rio.rioAssetId">
                        <mat-hint>Asset ID im System von RIO</mat-hint>
                    </mat-form-field>
                    <div class="flex-grow-1 w-50"></div>
                </div>
                <a (click)="rioEnterAssetId=false">Mit der VIN suchen</a>
            </ng-container>
            <ng-container *ngIf="!rioEnterAssetId">
                <div class="d-flex flex-row justify-content-between gap-3 align-items-center">
                    <mat-form-field appearance="fill" class="flex-grow-1">
                        <mat-label>VIN</mat-label>
                        <input matInput type="string" [(ngModel)]="rioVin">
                        <mat-hint>Fahrzeugidentifikationsnummer</mat-hint>
                    </mat-form-field>
                    <button class="flex-grow-1 w-50" *ngIf="rioVin?.length === 17 && rioVin !== rioResult?.vin; else enterVin" mat-button mat-raised-button color="primary" (click)="rioSearch()">Fahrzeug mit dieser VIN suchen</button><br>
                    <ng-template #enterVin>
                        <div class="flex-grow-1 w-50"></div>
                    </ng-template>
                </div>
                <div class="d-flex flex-row justify-content-between gap-3 align-items-center">
                    <mat-radio-group *ngIf="rioResult && data.connection.rio" [(ngModel)]="data.connection.rio.rioAssetId">
                        <mat-radio-button [value]="asset.id" *ngFor="let asset of rioResult.list">
                            <span class="rio-status">Status: {{asset.status}}<br></span>
                            <span class="rio-desc">Name: {{asset.name}}<br></span>
                            <span class="rio-license">Nummernschild: {{asset.licensePlate}}<br></span>
                            <span class="rio-brand">Hersteller: {{asset.brand}}</span> | <span class="rio-type">Typ: {{asset.type}}<br></span>
                            <span class="rio-id">ID: {{asset.id}}<br></span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <a (click)="rioEnterAssetId=true">RIO Asset ID direkt eingeben</a>
            </ng-container>
        </ng-container>

        <!-- Tronity -->
        <ng-container *ngSwitchCase="'Tronity'">
            <div style="margin-left: 14px">
                Fahrzeug nach dem Anlegen bitte mit <mat-icon style="transform: translate(0, 6px)">add_to_photos</mat-icon> in der Tabelle mit Tronity verbinden.
            </div>
        </ng-container>


        <!-- S-Tec -->
        <ng-container *ngSwitchCase="'S-Tec'">
            <ng-container *ngIf="(sTecAccounts | async) as sTecAccounts">
                <app-edit-ds-s-tec *ngIf="data.connection.sTec" [connection]="data.connection.sTec" [accounts]="sTecAccounts"></app-edit-ds-s-tec>
            </ng-container>
        </ng-container>

        <!-- Kuantic -->
        <ng-container *ngSwitchCase="'Kuantic'">
            <mat-form-field appearance="fill" *ngIf="data.connection.kuantic">
                <mat-label>VIN</mat-label>
                <input matInput type="string" [formControl]="kuanticVinCtrl">
                <mat-error *ngIf="kuanticVinCtrl?.errors?.errorText as error">{{error}}</mat-error>
                <mat-hint>Vehicle Information Number des Fahrzeugs</mat-hint>
            </mat-form-field>
        </ng-container>
        <!-- WebFleet -->
        <ng-container *ngSwitchCase="'WebFleet'">
            <ng-container *ngIf="data.connection.webfleet">
                <app-edit-webfleet [connection]="data.connection.webfleet"></app-edit-webfleet>
            </ng-container>
        </ng-container>
        <div *ngIf="data.connection.sTec">
          <app-help-box-icon class="info-icon" [iconType]="DialogType.INFO" #infoIcon></app-help-box-icon>
            <app-help-box [dialogType]="DialogType.INFO" key="S_TEC_VEHICLE_ADDED" [helpIcon]="infoIcon">
                <p>
                    Achtung: Es kann längere Zeit in Anspruch nehmen ein S-TEC Fahrzeug hinzufügen.
                </p>
            </app-help-box>
        </div>
    </ng-container>

    <hr>
    <div class="d-flex flex-row justify-content-between gap-3 align-items-start" *ngIf="!isAmpsPresent()">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>Minimaler Ladestrom (A)</mat-label>
            <input [formControl]="checkMinAmps" matInput type="number" [(ngModel)]="data.confMinAmps" required>
            <mat-hint>Der minimale Strom darf nicht kleiner sein, als das Fahrzeugtyp-Minimum</mat-hint>
            <mat-error *ngIf="checkMinAmps.errors?.required" >
                Wert ist erforderlich
            </mat-error>
            <mat-error *ngIf="checkMinAmps.errors?.result" >
                Wert ist zu klein
            </mat-error>
            <mat-error *ngIf="checkMinAmps.errors?.greaterThanMaxAmp">
                Wert ist größer als maximum
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
        <mat-label>Maximaler Ladestrom (A)</mat-label>
        <input [formControl]="checkMaxAmps" matInput type="number" [(ngModel)]="data.confMaxAmps" required>
        <mat-hint>Der maximale Strom darf nicht größer sein, als das Fahrzeugtyp-Maximum</mat-hint>
        <mat-error *ngIf="checkMaxAmps.errors?.required" >
            Anzahl ist erforderlich
        </mat-error>
        <mat-error *ngIf="checkMaxAmps.errors?.result" >
            Anzahl ist zu groß
        </mat-error>
        <mat-error *ngIf="checkMaxAmps.errors?.smallerThanMinAmp">
            Anzahl ist kleiner als minimum
        </mat-error>
    </mat-form-field>
    </div>
    <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>Default Ladestand (%)</mat-label>
            <input [formControl]="checkTargetSoc" matInput type="number" [(ngModel)]="data.defaultTargetSoc" required>
            <mat-hint>Ladestand, auf den aufgeladen wird, wenn kein besonderer Termin gesetzt ist.
                Wenn leer, wird best-SOC angenommen (i.d.R. 50%). "-1" lädt grundsätzlich nur mit Termin.
            </mat-hint>
            <mat-error *ngIf="checkTargetSoc.errors?.required" >
                Wert ist erforderlich
            </mat-error>
            <mat-error *ngIf="checkTargetSoc.errors?.invalidValue" >
                Default Ladestand muss zwischen 1 und 100 liegen
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="(rfids | async) as rfids; else loading" appearance="fill" subscriptSizing="dynamic">
        <mat-label>Hauptkarte</mat-label>
        <mat-select [(ngModel)]="data.mainCard">
            <mat-option [value]="undefined">Keine</mat-option>
            <mat-option *ngFor="let r of rfids" [value]="r.id">{{r.name}} <span class="data-source-info mat-caption">{{r.token}}</span></mat-option>
        </mat-select>
        <mat-hint>Wird für die Zuordnung der Kostenstelle genutzt</mat-hint>
    </mat-form-field>
    </div>

    <div>
        <mat-checkbox [(ngModel)]="data.canPauseCharging" class="change-fleet">
            DC-Ladepausen erlauben
            <br>
            <span class="mat-caption break">Wenn diese einstellung gesetzt ist, dürfen Ladevorgange dieses Fahrzeuges an DC-Ladepunkten durch den Ladeplan-Algorithmus pausiert werden.</span>
        </mat-checkbox>
    </div>

    <mat-checkbox *ngIf="!changeFleet && edit" [(ngModel)]="changeFleet" class="change-fleet">Flotte wechseln</mat-checkbox>
    <mat-form-field appearance="fill" *ngIf="changeFleet" class="change-fleet">
        <mat-label>Flotte</mat-label>
        <mat-select [(ngModel)]="data.fleetId">
            <mat-option *ngFor="let f of selectableFleet" [value]="f.id">{{f.name}}</mat-option>
        </mat-select>
        <mat-hint>Flotte des Fahrzeugs</mat-hint>
    </mat-form-field>
</div>
<ng-template #loading>
    Loading...
</ng-template>






