import {Component, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {VehicleService} from '../../vehicle/service/vehicle.service';
import {
    Evse, EvseActorArgs, Fleet, Load,
    Meter,
    PowerSupply,
    Rfid, SolarSystem, User,
    Vehicle
} from '@io-elon-common/frontend-api';
import {isArray} from 'rxjs/internal-compatibility';
import {AbstractTableComponent} from '../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {SearchDataConfig} from '../../../shared/helper/searchDataConfig';
import {EvseService} from '../../evse/service/evse.service';
import {RfidService} from '../../rfid/service/rfid.service';
import {MeterService} from '../../meter/service/meter.service';
import {PowerSupplyService} from '../../basis/service/power-supply.service';
import {LoadService} from '../../loads/service/load.service';
import {Subscription} from 'rxjs';
import {FleetService} from '../../vehicle/service/fleet.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SolarSystemService} from "../../solar/service/solar-system.service";
import {UserService} from '../../users/service/user.service';
import {BasisService} from '../../basis/service/basis.service';
import {SolarPanelService} from '../../solar/service/solar-panel.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';

export type SearchType = "vehicle" | "evse" | "rfid" | "meter" | "powerSupply" | "load" | "solarSystem" | "user"

interface ResultDetails {
    value: Array<{
        text: string,
        partialMatch: boolean,
        fullMatch: boolean
    }>
}

type Matches = {
    //Found full query string as word (whitespace or string end before & after)
    fullWord: number;
    //Fund full query string
    full: number;
    //Fund single words of the query string
    partial: number;
};

function compareMatches(a: Matches, b:Matches) {
    const fw = b.fullWord - a.fullWord;
    if(fw !== 0) {
        return fw;
    }
    const w = b.full - a.full;
    if(w !== 0) {
        return w;
    }
    return b.partial - a.partial;
}

function hasAnyMatch(m: Matches): boolean {
    return !!(m.fullWord || m.full || m.partial);
}

interface SearchResult {
    object: any,
    matches: Matches
    resultType: SearchType,
    links: Set<string>,
    fields: FieldResult[],
    fleetName: string | undefined
}

interface FieldResult {
    field: string;
    fieldDisplayName: string;
    value: string;
    matches: Matches,
    details: ResultDetails
}

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})

export class SearchViewComponent extends AbstractTableComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    displayedColumns: string[] = ['result', 'action', 'links'];
    dataSource = new MatTableDataSource<SearchResult>([]);
    selection = new SelectionModel<SearchResult>(true, []);

    public keyword: string = "";
    public showResultCount: boolean = false;
    public showLoading: boolean = false;

    private _searchAllFleets = false;
    private cid: any;
    private vehicles: Vehicle[] = [];
    private evses: Evse[] = [];
    private rfids: Rfid[] = [];
    private meters: Meter[] = [];
    private powerSupplies: PowerSupply[] = [];
    private searchResults: SearchResult[] = [];
    private solarSystems: SolarSystem[] = [];
    private users: User[] = [];
    private loads: Load[] = [];
    private selectedFleetSubscription!: Subscription;
    private selectedBasisSubscription!: Subscription;
    private fleetId: number | undefined;
    private basisId: number | undefined;
    private headerKeyObservable: Subscription | undefined;
    private fleets: Fleet[] = [];

    constructor(
        private readonly vehicleService: VehicleService,
        private readonly evseService: EvseService,
        private readonly rfidService: RfidService,
        private readonly meterService: MeterService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly searchDataConfig: SearchDataConfig,
        private readonly loadService: LoadService,
        private readonly solarSystemService: SolarSystemService,
        private readonly route: ActivatedRoute,
        private readonly fleetService: FleetService,
        private readonly userService: UserService,
        private readonly basisService: BasisService,
        private readonly solarPanelService: SolarPanelService,
        private readonly dialog: MatDialog,
        private readonly toastrService: ToastrService,
        private readonly router: Router
        ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.showLoading = true;
        this.fleets = await this.fleetService.getAllPromise();

        this.cid = this.route.snapshot.queryParams.cid;

        this.route.queryParams.subscribe((queryParams: any) => {
            if(queryParams.search) {
                this.keyword=decodeURIComponent(queryParams.search);
                this.search();
            }
            if(queryParams.cid) {
                if(this.cid !== queryParams.cid) {
                    this.cid = queryParams.cid;
                    (async () => {
                        this.showLoading = true;
                        try {
                            this.dataSource.data = [];
                            await this.loadAllData();
                            this.search();
                        } finally {
                            this.showLoading = false;
                        }
                    })();
                }
            }
        });

        this.selectedFleetSubscription = this.fleetService.selectedFleet.subscribe(async id => {
            if (id != null) {
                this.fleetId = id;
                this.selectedBasisSubscription?.unsubscribe();
                this.selectedBasisSubscription = this.fleetService.get(id).subscribe(fleet => {
                    this.basisId = fleet?.base.id;
                    //Skip search while loading, because we have a fleet + customer change and customer change handling
                    //will manage this. (We need to do new API Calls, so this search would run to early)
                    if (!this.showLoading) {
                        this.search();
                    }
                })
            }
        });

        await this.loadAllData();
        this.search();

        this.dataSource = new MatTableDataSource(this.searchResults);
        this.dataSource.paginator = this.paginator;
        this.showLoading = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.searchResults;
    }

    public set searchAllFleets(value: boolean) {
        this._searchAllFleets = value;
        this.search();
    }

    public get searchAllFleets() {
        return this._searchAllFleets;
    }

    public search() {
        if (!this.keyword) {
            return;
        }

        this.showResultCount = false;
        const keys = this.keyword.trim().split(" ");
        if(keys.length === 1 && keys[0] === '') {
            return;
        }

        this.searchResults = [];
        for (const vehicle of this.vehicles) {
            if (vehicle.canView && (this._searchAllFleets || vehicle.fleet.id === this.fleetId)) {
                this.searchResults.push(this.searchObject(vehicle, "vehicle", vehicle.fleet.name));
            }
        }

        for (const evse of this.evses) {
            if (this._searchAllFleets || evse.basis.id === this.basisId) {
                this.searchResults.push(this.searchObject(evse, "evse", this.getFleetNameFromBasisId(evse.basis.id)));
            }
        }

        for (const rfid of this.rfids) {
            if (rfid.canView) {
                this.searchResults.push(this.searchObject(rfid, "rfid", "Global"));
            }
        }

        for (const meter of this.meters) {
            if (this._searchAllFleets || meter.basis?.id === this.basisId) {
                this.searchResults.push(this.searchObject(meter, "meter", this.getFleetNameFromBasisId(meter.basis?.id)));
            }
        }

        for (const ps of this.powerSupplies) {
            if (this._searchAllFleets || ps.baseId === this.basisId) {
                this.searchResults.push(this.searchObject(ps, "powerSupply", this.getFleetNameFromBasisId(ps.baseId)));
            }
        }

        for (const load of this.loads) {
            const basisId = this.powerSupplies.find(ps => ps.id === load.powerSupplyId)?.baseId;
            this.searchResults.push(this.searchObject(load, "load", this.getFleetNameFromBasisId(basisId)));
        }

        for (const solarSystem of this.solarSystems) {
            if (this._searchAllFleets || solarSystem.basis?.id === this.basisId) {
                this.searchResults.push(this.searchObject(solarSystem, "solarSystem", this.getFleetNameFromBasisId(solarSystem.basis?.id)));
            }
        }

        for (const user of this.users) {
            this.searchResults.push(this.searchObject(user, "user", "Global"));
        }


        this.searchResults = this.searchResults.filter(r => hasAnyMatch(r.matches));
        this.searchResults.sort((a, b) => compareMatches(a.matches, b.matches));
        this.showResultCount = true;
        this.dataSource.data = this.searchResults;
    }

    private searchObject(object: any, searchType: SearchType, fleetName: string | undefined): SearchResult {
        const fieldResults = this.searchFields(object, searchType);

        return {
            object: object,
            matches: {
                fullWord: fieldResults.map(r => r.matches.fullWord).reduce((sum, count) => sum + count, 0),
                full: fieldResults.map(r => r.matches.full).reduce((sum, count) => sum + count, 0),
                partial: fieldResults.map(r => r.matches.partial).reduce((sum, count) => sum + count, 0)
            },
            resultType: searchType,
            links: this.getSupportingLink(searchType, object),
            fields: fieldResults.sort((a, b) => compareMatches(a.matches, b.matches)),
            fleetName: fleetName
        }
    }

    private searchString(val: string): Matches {
        //Search for fullWord matches
        const parts = val.toUpperCase().split(this.keyword.toUpperCase());

        const full = parts.length - 1;
        let fullWord = 0;

        //Start at 1, we do forward and backword lookup
        for(let i = 1; i < parts.length; i++) {
            //empty or ends with space
            const validBefore = /(^|\s)$/.test(parts[i-1]);
            //empty or starts with space
            const validAfter = /^(\s|$)/.test(parts[i]);

            if(validBefore && validAfter) {
                fullWord++;
            }
        }

        const keyParts = this.keyword.toUpperCase().split(/\s/);

        let partial = 0;
        for(const k of keyParts) {
            if(k.trim().length === 0) {
                continue;
            }

            partial += val.toUpperCase().split(k).length - 1;
        }

        return {fullWord, full, partial}
    }

    private searchActorArgs(actorArgs: EvseActorArgs[], fieldPath: string): FieldResult[] {
        return actorArgs.map(a => {
            const displayName = this.searchDataConfig.getActorArgDisplayName(a.key);
            return {
                field: fieldPath + "." + a.key,
                fieldDisplayName: displayName,
                value: a.value,
                matches: this.searchString(a.value),
                details: this.createDetailsText(a.value)
            };
        }).filter(f => hasAnyMatch(f.matches));
    }

    private searchFields(obj: any, parentPath: string): FieldResult[] {
        const result: Array<FieldResult | FieldResult[] | null> = Object.keys(obj).map(fieldName => {
            const value = obj[fieldName];
            const fieldPath = parentPath + "." + fieldName;

            if (this.searchDataConfig.isFieldIgnored(fieldPath)) {
                return null;
            }

            //Special handling for actor Args, the have name by key, not by property name
            if(fieldPath.endsWith("actorArgs")) {
                return this.searchActorArgs(value as any[], fieldPath)
            }

            if (typeof value === 'object') {
                return this.searchFields(value, fieldPath);
            }

            const fieldDisplayName = this.searchDataConfig.getDisplayName(fieldPath);
            if(fieldDisplayName === null) {
                //This field is not searchable
                return null;
            }

            const matches = this.searchString("" + value);

            if (!hasAnyMatch(matches)) {
                return null;
            }

            return {
                field: fieldPath,
                fieldDisplayName: fieldDisplayName,
                value: value,
                matches,
                details: this.createDetailsText(value)
            };


        });

        return this.flatten(result)
    }

    private flatten<T>(arr: Array<T |T[] | null>): T[] {
        const ret: T[] = [];
        for(const a of arr) {
            if(a === null) {
                continue;
            }
            if(isArray(a)) {
                ret.push(...a);
            } else {
                ret.push(a);
            }
        }
        return ret;
    }

    public createTitle(object: SearchResult): string {
        return this.searchDataConfig.getTitle(object.resultType, object.object);
    }

    public createRouterLink(object: SearchResult): string {
        return this.searchDataConfig.getRouterLink(object.resultType, object.object.id)
    }

    public createDetailsText(value: string): ResultDetails {
        const fullStart = value.toUpperCase().indexOf(this.keyword.toUpperCase());

        if(fullStart !== -1) {
            //We have a full match, underline it
            return {
                value: [{
                    text: value.substring(0, fullStart),
                    partialMatch: false,
                    fullMatch: false
                }, {
                    text: value.substr(fullStart, this.keyword.length),
                    partialMatch: false,
                    fullMatch: true
                }, {
                    text: value.substring(fullStart + this.keyword.length),
                    partialMatch: false,
                    fullMatch: false
                }]
            }
        } else {
            //We have partial matches, bold them
            const keyParts = this.keyword.toUpperCase().split(/\s/);
            const valueArr: ResultDetails["value"] = [];

            valueArr.push({
                text: "",
                partialMatch: false,
                fullMatch: false,
            });

            for(let i = 0; i < value.length; i++) {
                const last = valueArr[valueArr.length - 1];
                const bold = keyParts.some(k => {
                    const len = k.length;
                    const idx = value.toUpperCase().indexOf(k, i-len);
                    return idx !== -1 && idx <= i;
                });
                if(bold === last.partialMatch) {
                    last.text += value[i];
                } else {
                    valueArr.push({
                        text: value[i],
                        partialMatch: bold,
                        fullMatch: false
                    });
                }
            }

            return {
                value: valueArr
            }
        }



    }

    public creatSupportingLinks(object: SearchResult) {
        let result = "";
        object.links.forEach(link => {
            result += link + "<br>";
        });
        return result;
    }

    public textChanged(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.updateUrl();
            this.search();
        }
    }

    public updateUrl() {
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: { search: encodeURIComponent(this.keyword) },
                queryParamsHandling: 'merge'
            }
        );
    }

    public getTotalSearchResultCount(): number {
        return this.searchResults.length;
    }

    public async edit(element: SearchResult): Promise<void> {
        switch (element.resultType) {
            case "rfid":
                await this.rfidService.showEditDialog(element.object);
                break;
            case "user":
                await this.userService.showEditDialog(element.object);
                break;
            case "vehicle":
                await this.vehicleService.showEditDialog(element.object);
                break;
            case "meter":
                await this.meterService.showEditDialog(element.object);
                break;
            case "evse":
                await this.evseService.showEditDialog(element.object, this.powerSupplies);
                break;
            case "solarSystem":
                await this.solarSystemService.showEditDialog(element.object, {
                    // @ts-ignore
                    possibleMeters: this.meters.filter(meter => meter.basis?.id === element.object.basis.id)?.sort((a, b) => a.name.localeCompare(b.name)),
                    // @ts-ignore
                    possiblePowerSupplys: this.powerSupplies.filter(ps => ps.baseId === element.object.basis.id)?.sort((a, b) => a.name.localeCompare(b.name)),
                    basisId: element.object.basis.id
                });
                break;
            case "powerSupply":
                const basis = await this.basisService.getPromise(element.object.baseId);
                await this.powerSupplyService.showEditDialog(element.object, basis);
                break;
            case "load":
                // @ts-ignore
                await this.loadService.showEditDialog(element.object, this.powerSupplies.filter(ps => ps.baseId === this.basisId)?.sort((a, b) => a.name.localeCompare(b.name)));
                break;
        }
        await this.loadAllData();
        this.search();
    }

    public async delete(element: SearchResult): Promise<void> {
        switch (element.resultType) {
            case "rfid":
                await this.rfidService.showDeleteDialog(element.object, {});
                break;
            case "user":
                await this.userService.showDeleteDialog(element.object, {});
                break;
            case "vehicle":
                await this.vehicleService.showDeleteDialog(element.object, {});
                break;
            case "meter":
                await this.meterService.showDeleteDialog(element.object, {});
                break;
            case "solarSystem":
                await this.solarSystemService.showDeleteDialog(element.object, {});
                break;
            case "evse":
                await this.evseService.showDeleteDialog(element.object, {});
                break;
            case "load":
                await this.loadService.showDeleteDialog(element.object, {});
                break;
        }
        await this.loadAllData();
        this.search();
    }

    public async editPw(element: SearchResult): Promise<void> {
        await this.userService.showEditPwDialog(element.object);
        this.search();
    }

    public async addSolarPanel(element: SearchResult): Promise<void> {
        await this.solarPanelService.showNewDialog([element.object]);
    }

    public getValidations(evse: Evse) {
        if(evse.liveData.validationResult.some(v => v.validationResult === "INVALID" || v.validationResult === "VALIDATION_ERROR")) {
            return evse.liveData.validationResult;
        }
        return null;
    }

    public handleValidation(evse: Evse) {
        this.evseService.showValidationDialog(evse);
    }

    public async easeeLogin(evse: Evse): Promise<void> {
        await this.evseService.showEaseeLogin(evse);
    }

    public showUnlockButton(evse: Evse): boolean {
        return !!evse.liveData.evsePlugged && evse.authEnabled;
    }

    public async unlock(evse: Evse): Promise<void> {
        try {
            const response = await this.evseService.disableAuthTemp(evse.id);
            if(response.success) {
                this.toastrService.success("Ladepunkt wird freigegeben, bis das Auto abgesteckt wird.");
            } else {
                this.toastrService.warning("Fehler beim freigeben des Ladepunktes");
                console.error(response.result);
            }
        } catch (e) {
            this.toastrService.warning("Fehler beim freigeben des Ladepunktes");
        }
    }

    public async triggerSequence(evse: Evse) {
        try {
            const result = await this.evseService.triggerSequence(evse.id);
            if(result.success) {
                this.toastrService.success("Sequenz zur Fahrzeugerkennung wird gestartet.")
            } else {
                this.toastrService.error("Fehler beim starten der Sequenz.")
                console.error(result.result)
            }
        } catch (e) {
            this.toastrService.error("Fehler beim starten der Sequenz.")
        }
    }

    public async actions(evse: Evse) {
        await this.evseService.showActionDialog(evse);
    }

    private getSupportingLink(type: string, object: any): Set<string> {
        const link = this.searchDataConfig.getRoutingLinkReference(type, object);
        const result = new Set<string>();
        link?.forEach(link => {
            result.add("<i><a href='" + link.value + "'>" + link.key + "</a></i>");
        });
        return result;
    }

    private async loadAllData(): Promise<void> {
        const requests: Promise<unknown>[] = [];
        requests.push(this.loadService.getAllPromise().then(ls => this.loads = ls));
        requests.push(this.solarSystemService.getAllPromise().then(ss => this.solarSystems = ss));
        requests.push(this.vehicleService.getAllPromise().then(vs => this.vehicles = vs));
        requests.push(this.evseService.getAllPromise().then(es => this.evses = es));
        requests.push(this.rfidService.getAllPromise().then(rs => this.rfids = rs));
        requests.push(this.meterService.getAllPromise().then(ms => this.meters = ms));
        requests.push(this.powerSupplyService.getAllPromise().then(pss => this.powerSupplies = pss));
        requests.push(this.userService.getAllPromise().then(us => this.users = us));

        await Promise.all(requests);
    }

    private getFleetNameFromBasisId(basisId: number | undefined): string | undefined {
        if (basisId) {
            return this.fleets.find(f => f.base.id === basisId)?.name;
        }
        return undefined;
    }

    ngOnDestroy(): void {
        if (this.selectedFleetSubscription) {
            this.selectedFleetSubscription.unsubscribe();
        }
        if (this.selectedBasisSubscription) {
            this.selectedBasisSubscription.unsubscribe();
        }
        if (this.headerKeyObservable) {
            this.headerKeyObservable.unsubscribe();
        }
    }
}
