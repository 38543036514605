<div style="background: white;">
    <h2 class="center">Ladevorgänge
        <app-file-exporter class="download-icon" [data]="exportData" [fileName]="'chargings'"></app-file-exporter>
        <mat-icon class="refresh-icon" matTooltip="Neu Laden" (click)="refresh()">autorenew</mat-icon>
    </h2>
    <table mat-table [hidden]="error" [dataSource]="dataSource" class="full-width-table" matSort>

        <!-- Expander -->
        <ng-container matColumnDef="expander">
            <th mat-header-cell *matHeaderCellDef class="col-expander"></th>
            <td mat-cell *matCellDef="let element" class="col-expander">
                <div>˃</div>
            </td>
        </ng-container>

        <!-- Vehicle -->
        <ng-container matColumnDef="vehicle">
            <th mat-header-cell *matHeaderCellDef class="col-icon col-padding">Fahrzeug</th>
            <td mat-cell *matCellDef="let element" class="col-icon col-padding">
                <span *ngIf="(getVehicle(element.vehicleId) | async) as vehicle; else guest">
                    <app-vehicle-link [vehicle]="vehicle"></app-vehicle-link>
                </span>
                <ng-template #guest>
                    <span [matTooltip]="'Sie haben entweder keine Berechtigung die Details zu diesem Fahrzeug anzuzeigen oder das Fahrzeug wurde gelöscht.'">Autorisiertes Fahrzeug</span>
                </ng-template>
            </td>
        </ng-container>

        <!-- Evse -->
        <ng-container matColumnDef="evse">
            <th mat-header-cell *matHeaderCellDef class="col-icon col-padding">Ladepunkt</th>
            <td mat-cell *matCellDef="let element" class="col-icon col-padding">
                <span *ngIf="(getEvse(element.evseId) | async) as evse; else guestevse">
                    <app-evse-link [evse]="evse"></app-evse-link>
                </span>
                <ng-template #guestevse matTooltip="matTooltip">
                    <span [matTooltip]="'Sie haben entweder keine Berechtigung die Details zu diesem Ladepunkt anzuzeigen oder der Ladepunkt wurde gelöscht.'">Gesteuerter Ladepunkt</span>
                </ng-template>
            </td>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef class="col-start col-padding">Start</th>
            <td mat-cell *matCellDef="let element" class="col-start">
                {{element.tstStart | date}} - {{element.tstStart | date:'shortTime' }}
                <div *ngFor="let t of element.transfers" class="details">
                    ──{{t.tstStart | date}} - {{t.tstStart | date:'shortTime' }}
                </div>
            </td>
        </ng-container>

        <!-- End Column -->
        <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef class="col-start col-padding">Ende</th>
            <td mat-cell *matCellDef="let element" class="col-start">
                {{element.tstEnd | date}} - {{element.tstEnd | date:'shortTime' }}
                <div *ngFor="let t of element.transfers" class="details">
                    ──{{t.tstEnd | date}} - {{t.tstEnd | date:'shortTime' }}
                </div>
            </td>
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef class="col-duration col-padding"> Dauer</th>
            <td mat-cell *matCellDef="let element" class="col-duration col-padding">
                <span *ngIf="element.tstEnd; else active">{{(element.tstEnd - element.tstStart) | duration}}</span>
                <ng-template #active>Aktiv seit {{element.tstStart | age:1000*60*60*24*100: false}}</ng-template>
                <div *ngFor="let t of element.transfers" class="details">
                    <span *ngIf="t.tstEnd; else ongoing">{{(t.tstEnd - t.tstStart) | duration}}</span>
                </div>
                <ng-template #ongoing>Lädt noch</ng-template>
            </td>
        </ng-container>

        <!-- Power Column-->
        <ng-container matColumnDef="power">
            <th mat-header-cell *matHeaderCellDef class="col-energy col-padding">Geladene Energie¹</th>
            <td mat-cell *matCellDef="let element" class="col-energy col-padding">
                <span *ngIf="element.state === 'active'">bisher&nbsp;</span>
                <span *ngIf="element.energy !== undefined; else noEnergy">{{(element.energy/1000).toFixed(2)}} kWh¹</span>
                <ng-template #noEnergy>
                    <span
                        *ngIf="element.energyMath !== undefined; else noEnergyMath">{{(element.energyMath/1000).toFixed(2)}}
                        kWh²</span>
                </ng-template>
                <ng-template #noEnergyMath>{{(element.iCpEnergy/1000).toFixed(2)}} kWh³</ng-template>
                <div *ngFor="let t of element.transfers" class="details">
                    <span *ngIf="t.energyEnd !== undefined; else noEnergyTransfer">{{((t.energyEnd -
                        t.energyStart)/1000).toFixed(2)}} kWh¹</span>
                    <ng-template #noEnergyTransfer>
                        <span *ngIf="t.energyEndMath !== undefined; else noEnergyMathTransfer">{{((t.energyEndMath -
                            t.energyStartMath)/1000).toFixed(2)}} kWh²</span>
                    </ng-template>
                    <ng-template #noEnergyMathTransfer></ng-template>
                </div>
            </td>
        </ng-container>

        <!-- Cost Column -->
        <ng-container matColumnDef="cost">
            <th mat-header-cell *matHeaderCellDef class="col-vehicle col-padding"> Kosten⁴</th>
            <td mat-cell *matCellDef="let element" class="col-vehicle col-padding">
                <span *ngIf="element.state === 'active'">bisher&nbsp;</span>
                <span>{{element.price.toFixed(2)}}€</span>
            </td>
        </ng-container>

        <!-- RFID Column -->
        <ng-container matColumnDef="rfid" >
            <th mat-header-cell *matHeaderCellDef class="col-rfid col-padding">Kostenstelle / RFID</th>
            <td mat-cell *matCellDef="let element" class="col-rfid col-padding">
                <span *ngIf="element.kostenstelle; else rfid">{{element.kostenstelle}}</span>
                <ng-template #rfid>
                    <pre *ngIf="element.rfid; else noCost">{{element.rfid}}</pre>
                </ng-template>
                <ng-template #noCost>--</ng-template>
            </td>
        </ng-container>

        <!-- Stop Reason Column -->
        <ng-container matColumnDef="stopReason">
            <th mat-header-cell *matHeaderCellDef class="col-rfid col-padding">Grund für das Beenden</th>
            <td mat-cell *matCellDef="let element" class="col-rfid col-padding">
                <span *ngIf="element.stopReason; else stopReason">{{element.stopReason}}</span>
                <ng-template #stopReason>--</ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="col-actions col-padding"> </th>
            <td mat-cell *matCellDef="let element" class="col-actions col-padding">
                <div class="d-flex flex-row justify-content-end align-items-center gap-1">
                    <ng-container *ngIf="(element.state !== 'finished') && displayStopChargeButton" >
                        <span matTooltip="Sie haben keine Berechtigung den Ladevorgang zu stoppen/fortsetzen" [matTooltipDisabled]="element.canEdit">
                            <button mat-icon-button (click)="$event.stopPropagation(); stop(element)" *ngIf="!isStopped(element)" matTooltip="Ladevorgang stoppen" [disabled]="!element.canEdit">
                                <mat-icon class="iblack">report</mat-icon>
                            </button>

                            <ng-container *ngIf="isStopped(element)">
                                <button mat-icon-button *ngIf="!(isAllowContinue(element) | async)" matTooltip="Ladevorgang wurde gestoppt." [disabled]="!element.canEdit">
                                    <mat-icon  class="iblack">not_interested</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Ladevorgang fortsetzen" (click)="$event.stopPropagation(); continue(element)" *ngIf="isAllowContinue(element) | async" [disabled]="!element.canEdit">
                                    <mat-icon class="iblack">play_arrow</mat-icon>
                                </button>
                            </ng-container>
                        </span>
                    </ng-container>

                    <button mat-icon-button *ngIf="element.hasDetails && showMetaDownload() && !downloads[element.id]" [matTooltip]="'Metadaten herunterladen'"
                            (click)="$event.stopPropagation(); downloadMeta(element)">
                        <i class="fa-solid fa-download iblack scale-0x8"></i>
                    </button>

                    <button mat-icon-button *ngIf="element.hasDetails && showMetaDownload() && downloads[element.id]" [matTooltip]="'Laden'"
                            (click)="$event.stopPropagation(); downloadMeta(element)">
                        <i class="fa-solid fa-spin fa-circle-notch iblack"></i>
                    </button>

                    <button (click)="$event.stopPropagation(); details(element)" mat-icon-button matTooltip="Details"><mat-icon
                            class="c-blue-2">info</mat-icon></button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="expandable" *matRowDef="let row; columns: displayedColumns;"
            (click)="$event.stopPropagation(); $event ? selectRow(row) : null"
            [ngClass]="{selected: selectedIndex === row.tstStart}">
        </tr>
    </table>
    <mat-spinner *ngIf="!finished"></mat-spinner>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
    <span class="mat-caption" *ngIf="price && !error">
        1: Messungen wurden von einem in dem Ladepunkt verbauten Energiezähler durchgeführt. Nur für Ladepunkte mit OCPP
        Verfügbar<br>
        2: Werte basieren auf von dem Ladepunkt gemeldeten Storm und Leistungsmessungen. Diese Zahlen sollten eine gute
        Annäherung sein, sind aber nicht immer zuverlässig<br>
        3: Werte basieren auf den von IO-ELON vorgegebenen Ladeplänen. Es wurde keine Messung durchgeführt. Diese Zahlen
        weichen oft sehr Stark von dem realen Verbrauch ab.<br>
        4: Kosten basierend auf der Energiemessung<ng-container *ngIf="price !== '0 ct/kWh'"> bei einem Strompreis von {{price}}</ng-container>. Der Preis für die Berechnung kann in
        den Standorteinstellungen konfiguriert werden.
    </span>
    <div *ngIf="error">{{error}}</div>
</div>
